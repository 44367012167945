.ownLogo{
  background-color: #577d17;
  max-width: 1256px;
  margin: 0 auto 150px auto;
  padding: 35px 0;
  border-radius: 50px;
  .title{
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #fff;
  }
  .ownLogoInformation{
    display: flex;
    margin: 20px 0 0 0;
    padding: 0 50px;
    .boxImage{
      margin: 0 20px 0 0;
      width: 400px;
    }
    .rightPanel{
      margin: 50px 0 0 0;
      border-left: 2px solid rgba(90, 90, 90, 0.78);
      padding: 0 0 0 50px;
      .rightPanelTitle{
        font-size: 20px;
        color: #fff;
        font-weight: bold;
      }
      .rightPanelText{
        font-size: 18px;
        display: flex;
        color: #fff;
        margin: 10px 0;
        .decoration{
          font-weight: bold;
        }
      }
      .emailText{
        display: inline-block;
        text-decoration: none;
        margin: 5px 0 0 0;
        color: #fff;
      }
    }
  }
}

@media(max-width: 1256px){
  .ownLogo{
    margin: 0 25px 150px 25px;
  }
}

@media(max-width: 900px){
  .ownLogo{
    .ownLogoInformation{
      flex-direction: column;
      .rightPanel{
        order: 1;
        margin: 20px 0 30px 0;
        padding: 0 0 30px 0;
        border-left: none;
      }
      .boxImage{
        order: 2;
        margin: 0;
      }
    }
  }
}

@media(max-width: 600px){
  .ownLogo{
    .ownLogoInformation{
      padding: 0 20px;
      .boxImage{
        width: 250px;
      }
    }
  }
}

@media(max-width: 320px){
  .ownLogo{
    .ownLogoInformation{
      .boxImage{
        width: 200px;
      }
    }
  }
}