.boxes{
  max-width: 1256px;
  margin: 50px auto;
  display: flex;
  .leftPanel{
    width: 80%;
  }
  .rightPanel{
    border-left: 2px solid #666666;
    padding: 0 0 0 25px;
    margin: 0 0 0 50px;
    width: 20%;
    .rightPanelTitle{
      font-size: 26px;
      color: #487a16;
    }
    .boxesNavigation{
      .boxesList{
        margin: 30px 0 0 0;
      }
    }

  }
}

@media(max-width: 1256px){
  .boxes{
    margin: 50px 25px;
  }
}

@media(max-width: 1000px){
  .boxes{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .leftPanel{
      width: 100%;
      order: 2;
    }
    .rightPanel{
      border-left: none;
      border-bottom: 2px solid #666666;
      padding: 0 0 25px 0;
      order: 1;
      width: 100%;
      margin: 0 0 25px 0;
      .boxesNavigation{
        .boxesList{
          width: 100%;
          display: grid;
          gap: 15px;
          grid-template-columns: repeat(3, 1fr);
          margin: 0;
        }
      }

    }
  }
}

@media(max-width: 700px){
  .boxes{
    .rightPanel{
      .boxesNavigation{
        .boxesList{
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media(max-width: 500px){
  .boxes{
    .rightPanel{
      .boxesNavigation{
        .boxesList{
          grid-template-columns: repeat(1, 1fr);
          gap: 5px;
        }
      }
    }
  }
}